import React, { useEffect } from 'react'
import { graphql, Link } from "gatsby"
import Layout from '../../components/layout';
import { Container } from 'react-bootstrap';
import { SliceZone } from "@prismicio/react"
import { components } from '../../slices'
import { StaticImage } from "gatsby-plugin-image"
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Project({ data }) {
  const project = data.prismicProject.data
  // allProjectUids => an array with all project uid for previous / next buttons.
  // projects are ORDERED BY CATEGORY at query
  const allProjectUids = data.allPrismicProject.nodes.map(proj => proj.uid)
  const indexOfUid = allProjectUids.indexOf(data.prismicProject.uid)
  // prevLink: if you are at the first project go to the last one, else, the previus one.
  let prevLink = "/projects/";
  indexOfUid === 0 ? prevLink += allProjectUids.pop() : prevLink += allProjectUids[indexOfUid - 1]
  // nextLink: if you are at the last project go to the first one, else, at the next one.
  let nextLink = "/projects/";
  indexOfUid === allProjectUids.length - 1 ? nextLink += allProjectUids[0] : nextLink += allProjectUids[indexOfUid + 1]

  useEffect(() => {
    AOS.init({
      offset: -10,
      delay: 50,
      duration: 800,
      easing: 'ease',
      // mirror: true
    });
  }, [])

  return (
    <Layout>
      <Container fluid className='project-page pt-5'>
        <div className='project-header my-4 mt-lg-5 d-flex flex-column flex-sm-row justify-content-between' data-aos="fade">
          <h3>{project.project_category}</h3>
          <h3>{project.project_year}</h3>
          <h3>{project.project_area}</h3>
          {project.project_size && <h3>{project.project_size}&#13217;</h3>}
        </div>
        <h1 className='mb-3 mb-md-4' data-aos="fade">{project.project_title}</h1>
        <div className='project-content d-flex flex-column align-items-center'>
          <SliceZone slices={data.prismicProject.data.body} components={components} />
        </div>

        <div className="pagination d-flex justify-content-center mt-3 px-3">
          <Link to={prevLink} className="pag-prev p-3 bg-light border-0">
            <StaticImage
              src='../../images/shapes/leftArrow.png'
              alt="previous arrow"
            />
          </Link>
          <Link to={nextLink} className="pag-next p-3 bg-light border-0">
            <StaticImage
              src='../../images/shapes/Group 2.svg'
              alt="next arrow"
            />
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($id: String){
    allPrismicProject(sort: {fields: tags}) {
      nodes {
        uid
        tags
      }
    }
    prismicProject(id: {eq: $id}){
      id
      uid
      data {
        project_title
        project_year
        project_size
        project_main_image {
          gatsbyImageData
        }
        project_category
        project_area
        body {
          ... on PrismicProjectDataBodyEmbedVideo {
            id
            slice_type
            primary {
              video_url {
                html
              }
              video_subtitle
              video_title
            }
          }
          ... on PrismicProjectDataBodyRichText {
            id
            slice_type
            primary {
              main_content {
                richText
              }
            }
          }
        }
      }
      tags
      type
  }
}
`